import { PATH_GROUP } from '../../routes/paths';
import SvgIconStyle from '../../components/shared/SvgIconStyle';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  details: getIcon('ic_reminders'),
  user: getIcon('ic_user'),
  groups: getIcon('ic_groups'),
  fleet: getIcon('ic_vehicle'),
  billing: getIcon('ic_cart'),
  invoices: getIcon('ic_settings'),
};

export function getSideBarConfig(namespace: string, accessLevel: string | undefined) {

  // default routes
  return [
    {
      subheader: 'project settings',
      items: [
        {
          title: 'details',
          path: `${PATH_GROUP.root}/${namespace}/settings/details`,
          icon: ICONS.details,
        },
        {
          title: 'members',
          path: `${PATH_GROUP.root}/${namespace}/settings/members`,
          icon: ICONS.user
        },
        {
          title: 'groups',
          path: `${PATH_GROUP.root}/${namespace}/settings/groups`,
          icon: ICONS.groups,
        },
        {
          title: 'vehicles',
          path: `${PATH_GROUP.root}/${namespace}/settings/vehicles`,
          icon: ICONS.fleet,
        },
        {
          title: 'invoice settings',
          path: `${PATH_GROUP.root}/${namespace}/settings/invoices`,
          icon: ICONS.invoices,
        },
        {
          title: 'billing',
          path: `${PATH_GROUP.root}/${namespace}/settings/billing`,
          icon: ICONS.billing,
        },
      ]
    }
  ]
}



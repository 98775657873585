import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack5';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';
import { Project } from '../../../@types/project'
import { AccountingConfiguration } from '../../../@types/accounting'
import { useUpdateInvoiceConfigurationMutation } from '../../../services/invoice';

export type InvoiceSettingsUpdateFormFormValues = {
    terms: string | null;
};

export const FormSchema = Yup.object().shape({
    terms: Yup.string()
        .nullable()
        .max(500),
});

export default function InvoiceSettingsUpdateForm({ project, accountingConfiguration }: { project: Project, accountingConfiguration: AccountingConfiguration }) {

    const [updateAcountingConfiguration] = useUpdateInvoiceConfigurationMutation();
    const { enqueueSnackbar } = useSnackbar();
    const {
        control,
        handleSubmit,
        reset,
        formState: { isSubmitting, isDirty, isValid }
    } = useForm<InvoiceSettingsUpdateFormFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(FormSchema),
        defaultValues: {
            terms: accountingConfiguration.invoiceConfiguration.terms || '',
        }
    });

    const onSubmit = async (data: InvoiceSettingsUpdateFormFormValues) => {

        try {
            // update the configuration
            var result = await updateAcountingConfiguration({
                organisationId: project.id,
                invoiceConfiguration: {
                    terms: data.terms,
                }
            }).unwrap();

            enqueueSnackbar('Successfully updated invoice configuration', { variant: 'success' });
            reset({
                terms: data.terms,
            });
        } catch (error) {
            enqueueSnackbar('Error occurred updating invoice configuration', { variant: 'error' });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                <Typography variant="h6">Invoice Branding</Typography>
                <Typography variant="body2">Customise the look and feel of your invoices</Typography>
                <Controller
                    name="terms"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Terms and Conditions"
                            error={Boolean(error)}
                            multiline={true}
                            rows={10}
                            helperText={Boolean(error) ? error?.message : 'This text appears at the bottom of invoices and should be populated with any terms and conditions or details you need your customers to know'}
                            autoComplete={'off'}
                        />
                    )}
                />
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isDirty || !isValid}
                >
                    Update Settings
                </LoadingButton>
            </Stack>
        </form>
    );
}

import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, useRoutes, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/app';
import AppsLayout from '../layouts/AppsLayout';
import ProjectLayout from '../layouts/project';
import SettingsLayout from '../layouts/settings';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import NavbarOnlyLayout from '../layouts/NavbarOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import AccountSetupGuard from '../guards/AccountSetupGuard';
import OrganisationGuard from '../guards/OrganisationGuard';
import { RequireAuth } from '../guards/RequireAuth';
import AdministratorAccessLevelGuard from '../guards/AdministratorAccessLevelGuard';
import LoadingScreen from '../components/shared/LoadingScreen';
import ErrorComponent from '../components/shared/error/ErrorComponent';
import AuthLayout from 'layouts/AuthLayout';

const GroupRedirect = () => {

  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  var projectFromStorage = localStorage.getItem('veem-project')
  var storedProject = projectFromStorage ? JSON.parse(projectFromStorage) : { projectId: null, projectIdentifier: null };

  const [query, setQuery] = useQueryParams({
    error: StringParam,
    error_description: StringParam
  });

  useEffect(() => {
    if (query.error_description) {
      localStorage.removeItem('veem-project');
      //setError(query.error_description);
    } else {
      // reset the error as was a successful redirect
      setError(null);

      if (storedProject.projectIdentifier) {
        // Saved project so direct there
        navigate(`/group/${storedProject.projectIdentifier}/dashboard`)
      } else {
        // Nothing saved so just show the project list
        navigate(`/app/projects`)
      }
    }
  }, [storedProject, query]);

  if (error) {
    return <ErrorComponent description={error} />
  }

  return <></>;
};

const Loadable = (Component: React.ElementType) => (props: any) => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  return useRoutes([
    {
      path: 'docs',
      element: (
        <MainLayout />
      ),
      children: [
        { path: 'analytics', element: <AccountingAnalytics /> },
        {
          path: 'fleet',
          children: [
            { path: '/', element: <Navigate to="/docs/fleet/compliance" replace /> },
            { path: 'compliance', element: <UserProfile /> },
          ]
        },
      ]
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'register/pending',
          element: (
            <RegisterPending />
          )
        },
        {
          path: 'activation/:activationToken',
          element: (
            <GuestGuard>
              <Activation />
            </GuestGuard>
          )
        },
      ]
    },
    {
      path: 'account',
      element: (
        <NavbarOnlyLayout />
      ),
      children: [
        { path: 'setup', element: <UserAccountComplete /> },
      ]
    },
    // Apps Routes
    {
      path: 'apps',
      element: (
        <AuthGuard>
          <AppsLayout />
        </AuthGuard>
      ),
      children: [
        { path: ':app/oauth', element: <IntegrationOAuth /> },
      ]
    },

    // App Routes
    {
      path: 'app',
      element: (
        <AuthGuard>
          <AccountSetupGuard>
            <DashboardLayout />
          </AccountSetupGuard>
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/app/projects" replace /> },
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/app/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'setup', element: <UserProfile /> },
          ]
        },
        {
          path: 'projects',
          element: <Projects />
        },
        {
          path: 'projects/create',
          element: <ProjectCreate />
        },
        {
          path: 'groups',
          element: <Navigate to="/app/projects" replace />
        },
      ]
    },
    {
      // This route handles the auth redirect when switiching organisations
      path: 'group/callback',
      element: (
        <DashboardLayout />
      ),
      children: [
        {
          path: '',
          element: <GroupRedirect />
        }
      ]
    },

    // Group Routes
    {
      path: 'group/:id/settings',
      element: (
        <AuthGuard>
          {/* <AccountSetupGuard> */}
          <OrganisationGuard>
            <SettingsLayout />
          </OrganisationGuard>
          {/* </AccountSetupGuard> */}
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <SettingsPage />
        },
        {
          path: 'members',
          element: <ProjectSettings />
        },
        {
          path: 'groups',
          children: [
            {
              path: '',
              element: <Groups />,
            },
            {
              path: 'create',
              element: <GroupCreate />
            },
            {
              path: ':id',
              element: <Group />
            },
          ]
        },
        {
          path: 'vehicles',
          element: <VehicleSettings />
        },
        {
          path: 'details',
          element: <ProjectBranding />
        },
        {
          path: 'billing',
          element: <BillingDashboard />
        },
        {
          path: 'subscriptions',
          element: <Subscriptions />
        },
        {
          path: 'invoices',
          element: <InvoiceSettings />
        },
      ]
    },
    {
      path: 'group/:id',
      element: (
        <AuthGuard>
          <AccountSetupGuard>
            <OrganisationGuard>
              <ProjectLayout />
            </OrganisationGuard>
          </AccountSetupGuard>
        </AuthGuard>
      ),
      children: [
        { path: 'dashboard', element: <GroupDashboard /> },
        {
          path: 'fleet',
          children: [
            { path: 'create', element: <AuthGuard requiredPermission="module/fleet"><VehicleCreatePage /></AuthGuard> },
            { path: 'vehicles', element: <AuthGuard requiredPermission="module/fleet" ><Vehicles /></AuthGuard> },
            {
              path: 'vehicles/:vehicleId/:tab/*',
              element: <VehicleLayout />,
            },
          ]
        },
        {
          path: 'jobs',
          children: [
            {
              path: '',
              element: <JobsPage />
            },
            {
              path: ':tab/*',
              element: <JobsPage />,
            },
            {
              path: ':jobId',
              element: <JobPage />
            },
            {
              path: 'type/:serviceTypeId',
              element: <ServiceTypePage />
            }
          ]
        },
        {
          path: 'bookings',
          children: [
            {
              path: '',
              element: <JobBookingsPage />
            },
          ]
        },
        {
          path: 'inventory',
          children: [
            {
              path: '',
              element: <InventoryItemsPage />
            },
            {
              path: ':inventoryItemId',
              element: <InventoryItemPage />
            }
          ]
        },
        {
          path: 'analytics',
          element: <AdministratorAccessLevelGuard>
            <AccountingAnalytics />
          </AdministratorAccessLevelGuard>
        },
        {
          path: 'invoices',
          children: [
            { path: '', element: <Invoices /> },
            { path: ':invoiceId', element: <InvoiceDetail /> },
          ]
        },
        {
          path: 'compliances',
          element: <Compliances />
        },
        {
          path: 'customers',
          children: [
            { path: '', element: <Clients /> },
            { path: 'create', element: <ClientCreatePage /> },
            {
              path: ':clientId/:tab/*',
              element: <ClientLayout />,
            },
          ]
        },
        {
          path: 'tasks',
          element: <RequireAuth route="tasks" requiredPermission="module/fleet" component={TaskDashboard} />
        },
        {
          path: 'integrations',
          children: [
            { path: '', element: <Integrations /> },
            { path: 'oauth', element: <IntegrationOAuth /> },
            { path: ':integrationId', element: <Integration /> },
            { path: ':integrationId/configure/*', element: <IntegrationConfigure /> },
          ]
        },
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <Navigate to="/group/callback" replace /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const RegisterPending = Loadable(lazy(() => import('../pages/authentication/RegisterPending')));
const Activation = Loadable(lazy(() => import('../pages/authentication/Activation')));
const UserAccountComplete = Loadable(lazy(() => import('../pages/app/UserAccountComplete')));

// Dashboard
const Vehicles = Loadable(lazy(() => import('../pages/vehicle/Vehicles')));
const UserProfile = Loadable(lazy(() => import('../pages/app/UserProfile')));

// Compliances
const Compliances = Loadable(lazy(() => import('../pages/compliance/Compliances')));

// Settings
const ProjectSettings = Loadable(lazy(() => import('../pages/project/ProjectSettings')));
const VehicleSettings = Loadable(lazy(() => import('../pages/vehicle/VehicleSettings')));
const InvoiceSettings = Loadable(lazy(() => import('../pages/invoice/InvoiceSettings')));
const ProjectBranding = Loadable(lazy(() => import('../pages/project/ProjectBranding')));
const SettingsPage = Loadable(lazy(() => import('../pages/settings/SettingsPage')));
const BillingDashboard = Loadable(lazy(() => import('../pages/billing/BillingDashboard')));
const Subscriptions = Loadable(lazy(() => import('../pages/billing/Subscriptions')));

//projects
const Projects = Loadable(lazy(() => import('../pages/project/Projects')));
const ProjectCreate = Loadable(lazy(() => import('../pages/project/ProjectCreate')));

// Groups
const Group = Loadable(lazy(() => import('../pages/group/Group')));
const Groups = Loadable(lazy(() => import('../pages/group/Groups')));
const GroupCreate = Loadable(lazy(() => import('../pages/group/GroupCreate')));
const GroupDashboard = Loadable(lazy(() => import('../pages/group/GroupDashboard')));

// Vehicle
const VehicleLayout = Loadable(lazy(() => import('../layouts/vehicle')));
const VehicleCreatePage = Loadable(lazy(() => import('../pages/vehicle/VehicleCreate')));


// Inventory
const InventoryItemsPage = Loadable(lazy(() => import('../pages/inventory/InventoryItems')));
const InventoryItemPage = Loadable(lazy(() => import('../pages/inventory/InventoryItem')));

// Accounting
const AccountingAnalytics = Loadable(lazy(() => import('../pages/accounting/AccountingAnalytics')));

// Integrations
const Integrations = Loadable(lazy(() => import('../pages/integration/Integrations')));
const Integration = Loadable(lazy(() => import('../pages/integration/Integration')));
const IntegrationConfigure = Loadable(lazy(() => import('../pages/integration/IntegrationConfigure')));
const IntegrationOAuth = Loadable(lazy(() => import('../pages/integration/IntegrationOAuth')));

// Tasks
const TaskDashboard = Loadable(lazy(() => import('../pages/tasks/TaskDashboard')));

// Invoices
const Invoices = Loadable(lazy(() => import('../pages/invoice/Invoices')));
const InvoiceDetail = Loadable(lazy(() => import('../pages/invoice/InvoiceSummary')));


// Jobs
const JobPage = Loadable(lazy(() => import('../pages/job/JobPage')));
const JobsPage = Loadable(lazy(() => import('../pages/job/JobsPage')));
const ServiceTypePage = Loadable(lazy(() => import('../pages/job/ServiceTypePage')));
const JobBookingsPage = Loadable(lazy(() => import('../pages/job/JobBooking')));

// Clients
const ClientLayout = Loadable(lazy(() => import('../layouts/client')));
const Clients = Loadable(lazy(() => import('../pages/client/Clients')));
const ClientCreatePage = Loadable(lazy(() => import('../pages/client/ClientCreatePage')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

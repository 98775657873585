import { Page, View, Text, Font, Image, Document, StyleSheet } from '@react-pdf/renderer';
import { fNumberFinancial, fCurrencyDecimal } from '../../utils/formatNumber';
import { fDate } from '../../utils/formatTime';
import { Invoice } from '../../@types/invoice';
import { AccountingConfiguration } from '../../@types/accounting';
import { Project } from '../../@types/project';

Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }]
});

const styles = StyleSheet.create({
  col4: { width: '25%' },
  col5: { width: '35%' },
  col6: { width: '50%' },
  col7: { width: '60%' },
  col8: { width: '75%' },
  col12: { width: '100%' },
  mb6: { marginBottom: 6 },
  mb8: { marginBottom: 8 },
  mb20: { marginBottom: 20 },
  mb30: { marginBottom: 30 },
  mb40: { marginBottom: 40 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: 700,
    letterSpacing: 1.2,
    textTransform: 'uppercase'
  },
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 13, fontWeight: 700 },
  h5: { fontSize: 12, fontWeight: 600 },
  body1: { fontSize: 10 },
  body2: { fontSize: 9 },
  body: { fontSize: 10 },
  subtitle2: { fontSize: 9, fontWeight: 700 },
  alignRight: { textAlign: 'right' },
  page: {
    padding: '40px 40px 40px 40px',
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    backgroundColor: '#fff',
  },
  footer: {
    left: 0,
    right: 0,
    bottom: 0,
    padding: 24,
    margin: 'auto',
    borderTopWidth: 1,
    borderStyle: 'solid',
    position: 'absolute',
    borderColor: '#DFE3E8'
  },
  gridContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  gridContainerColumn: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
  table: { display: 'flex', width: 'auto' },
  tableHeader: {
    backgroundColor: '#F8F9FA',
  },
  flexEnd: { justifyContent: 'flex-end' },
  tableBody: {},
  tableRow: {
    padding: '5px',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DFE3E8'
  },
  noBorder: { paddingTop: 5, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: { width: '5%' },
  tableCell_2: { width: '50%', paddingRight: 10 },
  tableCell_3: { width: '15%' },
  tableCell_4: { width: '95%' }
});

type InvoicePDFProps = {
  invoice: Invoice;
  project: Project;
  configuration: AccountingConfiguration;
};

function InvoicePDF({ invoice, project, configuration }: InvoicePDFProps) {

  const { invoiceNumber, status, lineItems, total, amountDue, subTotal, dueDate, date } = invoice;
  const { logo } = project.branding;
  const { invoiceConfiguration } = configuration;
  const { projectName, legalTradingName } = project;

  return (
    <Document>
      <Page size="A4" style={styles.page}>


        <View style={[styles.gridContainer, styles.mb6]}>
          <View>
            {logo && (<Image style={{ height: '50px', objectFit: 'contain' }} src={logo} />)}
          </View>
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={styles.h3}>Tax Invoice</Text>
            <Text>{status}</Text>
          </View>
        </View>


        <View style={[styles.gridContainer, styles.mb20]}>
          <View style={styles.col7}>
            <Text style={styles.subtitle2}>{legalTradingName ?? projectName}</Text>
            <Text style={styles.body2}>{project.branding.contactEmail}</Text>
            <Text style={styles.body2}>{project.branding.contactPhoneNumber}</Text>
          </View>

          <View style={[styles.col5, styles.gridContainer]}>
            <View style={[styles.col6]}>
              <Text style={styles.subtitle2}>Invoice Number</Text>
              <Text style={styles.subtitle2}>Invoice Date</Text>
              <Text style={styles.subtitle2}>Due Date</Text>
            </View>
            <View style={[styles.col6, styles.flexEnd]}>
              <Text style={[styles.body2, styles.alignRight]}>{invoiceNumber}</Text>
              <Text style={[styles.body2, styles.alignRight]}>{date ? fDate(date) : ''}</Text>
              <Text style={[styles.body2, styles.alignRight]}>{dueDate ? fDate(dueDate) : ''}</Text>
            </View>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb20]}>
          <View style={styles.col12}>
            <Text style={styles.subtitle2}>Bill to {invoice.entity.name}</Text>
            <Text style={styles.body1}>{invoice.entity.emailAddress}</Text>
          </View>
        </View>

        {/* Full width reference and description */}
        <View style={[styles.gridContainer, styles.mb6]}>
          <View style={styles.col12}>
            <Text style={[styles.overline, styles.mb8]}>Reference</Text>
            <Text style={styles.body1}>{invoice.reference}</Text>
          </View>
        </View>
        {/* <View style={[styles.gridContainer, styles.mb20]}>
          <View style={styles.col12}>
            <Text style={[styles.overline, styles.mb8]}>Description</Text>
            <Text style={styles.body2}>{invoice.description}</Text>
          </View>
        </View> */}
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>
              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Item & Description</Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Qty</Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Price</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>Total</Text>
              </View>
            </View>
          </View>

          <View style={styles.tableBody}>
            <View style={[styles.tableRow]} key="description-row">
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>{1}</Text>
              </View>
              <View style={styles.tableCell_4}>
                <Text>{invoice.description}</Text>
              </View>
            </View>
            {lineItems.map((item, index) => (
              <View style={[styles.tableRow]} key={item.lineItemId}>
                <View style={styles.tableCell_1}>
                  <Text style={styles.subtitle2}>{index + 2}</Text>
                </View>
                <View style={styles.tableCell_2}>
                  <Text>{item.description}</Text>
                </View>
                <View style={styles.tableCell_3}>
                  <Text>{item.unitAmount ? fNumberFinancial(item.quantity) : '-'}</Text>
                </View>
                <View style={styles.tableCell_3}>
                  <Text>{item.unitAmount ? fCurrencyDecimal(item.unitAmount) : '-'}</Text>
                </View>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{item.lineAmount ? fCurrencyDecimal(item.lineAmount) : '-'}</Text>
                </View>
              </View>
            ))}

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>Subtotal</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>${fNumberFinancial(subTotal)}</Text>
              </View>
            </View>
            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>GST.</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>${fNumberFinancial(total - subTotal)}</Text>
              </View>
            </View>

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text style={styles.h5}>Total</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.h5}>${fNumberFinancial(total)}</Text>
              </View>
            </View>
            <View style={[styles.tableRow]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={[styles.tableCell_3]}>
                <Text style={styles.h5}>Amount Due</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.h5}>${fNumberFinancial(amountDue)}</Text>
              </View>
            </View>
          </View>
        </View>

        {invoiceConfiguration?.terms && (
          <View style={[styles.gridContainer, styles.footer]}>
            <View style={styles.col12}>
              <Text>
                {invoiceConfiguration?.terms}
              </Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
}

export default InvoicePDF;

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';
import { Project } from '../../../@types/project'
import { EmailVerification } from '../../../@types/email'
import { useUpdateProjectMutation, useUpdateProjectEmailMutation } from '../../../services/projects';
import { UploadImage } from '../../../components/upload';
import EmailVerificationComponentModal from '../../../components/authentication/verify-code/EmailVerificationComponent';

export type ProjectUpdateFormFormValues = {
    projectName: string;
    legalTradingName: string;
    projectDescription: string;
    contactEmail: EmailVerification | null;
    contactPhone: string | null;
    emailVerificationId: string | null;
    logo: string | null;
};

export const FormSchema = Yup.object().shape({
    projectName: Yup.string()
        .required('Project name is required')
        .max(50),
    legalTradingName: Yup.string()
        .required('Legal / Trading name is required')
        .max(50),
    projectDescription: Yup.string()
        .max(128)
        .nullable(),
    contactPhone: Yup.string()
        .max(128)
        .nullable(),
});

export default function ProjectUpdateForm({ project }: { project: Project | null }) {

    const [updateProject] = useUpdateProjectMutation();
    const {
        control,
        handleSubmit,
        setError,
        setValue,
        watch,
        reset,
        formState: { isSubmitting, isDirty, isValid }
    } = useForm<ProjectUpdateFormFormValues>({
        mode: 'onTouched',
        resolver: yupResolver(FormSchema),
        defaultValues: {
            projectName: project?.projectName ?? '',
            legalTradingName: project?.legalTradingName ?? '',
            contactEmail: {
                email: project?.branding.contactEmail ?? '',
                verificationId: '',
            },
            contactPhone: project?.branding.contactPhoneNumber ?? '',
            logo: project?.branding.logo
        }
    });

    const onSubmit = async (data: ProjectUpdateFormFormValues) => {

        try {
            // update the project details
            var result = await updateProject({
                projectIdentifier: project?.identifier || '',
                projectName: data.projectName,
                legalTradingName: data.legalTradingName,
                contactEmail: data.contactEmail ? {
                    email: data.contactEmail.email,
                    verificationId: data.contactEmail.verificationId,
                } : null,
                contactPhoneNumber: data.contactPhone,
                logo: data.logo,
                emailVerificationId: data.emailVerificationId,
            }).unwrap();

            reset({
                projectName: data.projectName ?? '',
                legalTradingName: data?.legalTradingName ?? '',
                contactEmail: data.contactEmail ? {
                    email: data.contactEmail.email,
                    verificationId: data.contactEmail.verificationId,
                } : null,
                contactPhone: result.branding.contactPhoneNumber ?? '',
                logo: result.branding.logo
            });
        } catch (error) {
            if (error && error.modelState) {
                Object.keys(error.modelState).forEach((key: any) => {
                    let errors: string[] = Object.keys(error.modelState[key]).map((errorKey: string) => {
                        return error.modelState[key][errorKey];
                    })

                    // set the erorrs on the form
                    setError(key, {
                        type: "manual",
                        message: errors.join(',')
                    })
                })
            } else {

            }
        }
    };

    function onDropHandler(files: Blob[]) {
        if (files.length > 0) {
            var file = files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    setValue('logo', event.target.result, { shouldDirty: true });
                };
                reader.readAsDataURL(file);
            }
        }
    }

    const imageWatch = watch('logo');

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                <Typography variant="subtitle2">Branding Logo</Typography>
                <Typography variant="caption">This logo is public to your customers and will appear on items such as invoices. If a logo is not supplied nothing will be displayed.</Typography>
                <UploadImage
                    file={imageWatch}
                    onDrop={onDropHandler}
                    maxSize={100000000}
                    onRemove={() => setValue('logo', null, { shouldDirty: true })}
                    accept={['image/jpeg', 'image/png']} uploadText={'Upload logo'}
                />
                <Controller
                    name="projectName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Project Name (required)"
                            error={Boolean(error)}
                            helperText={Boolean(error) ? error?.message : 'The project name will be displayed within the application and to your customers '}
                            autoComplete={'off'}
                        />
                    )}
                />
                <Controller
                    name="legalTradingName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Legal / Trading Name (required)"
                            error={Boolean(error)}
                            helperText={Boolean(error) ? error?.message : 'The project name will be displayed within the application and to your customers '}
                            autoComplete={'off'}
                        />
                    )}
                />
                <Controller
                    name="contactEmail"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <EmailVerificationComponentModal
                            email={field.value?.email}
                            onCodeVerified={(email: string) => { }}
                            onEmailChange={(emailVerification: EmailVerification) => field.onChange({
                                email: emailVerification.email,
                                verificationId: emailVerification.verificationId,
                            })}
                        />
                    )}
                />
                <Controller
                    name="contactPhone"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Contact Phone"
                            error={Boolean(error)}
                            helperText={Boolean(error) ? error?.message : 'This number is used as a contact number in email communications'}
                            autoComplete={'off'}
                        />
                    )}
                />
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!isDirty || !isValid}
                >
                    Update Project
                </LoadingButton>
            </Stack>
        </form>
    );
}
